import React from "react"
import Layout from "../components/simpleLayout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import "../utils/css/components/home.css"
import siteConfig from '../../config'
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = () => {
   const data = useStaticQuery(graphql`
    query Query {
      file(relativePath: {eq: "bg.jpg"}) {
        publicURL
      }
    }
  `)

  return(
  <Layout>
    <SEO title="Home" keywords={siteConfig.keywords}/>
    {/* <h1>Hello</h1> */}
{/* 
    <section id="home">
      <div className="home-title">
        <h1 className="text-accent">Asish's </h1> <div> <span className="creative">creative</span> </div><h1  className="text-accent"> Hub</h1> 
      </div>
    </section> */}

    
    <section id="home1">
      <a id="rotate"href="/">Asish's Creative Hub</a>
    </section>

    <section className="homeSection">
      <div className="container width">
        <div className="row">
          <div className="col-6" id="photo" style={{padding:'60px'}}>
            <img src={data.file.publicURL} className="border"alt="profileImg"></img>
          </div>
          <div className="col-6" style={{padding:'30px'}}>
            <h1>Who am I?</h1>
              <p className="text-accent1">
              I am Asish George, <span id="myRole"></span><br/>I am currently working as a Software Engineer at Innovation Incubator Advisory.
            </p>
            {/* <h1>Why this site?</h1>
            <p className="text-accent1">
              I made this site as documentation hub for all my learnings both technical and non technical. It will be useful for me to reflect on and learn from my personal experiences. Some of my experiences my be usef
            </p> */}
            <div className="row">
            <div className="col-12">
              <h3 className="margin-sm">Want to work with me?</h3>
              <p className="text-accent1">Drop me an email at <a href="mailto:contact.asishgeorge@gmail.com">contact.asishgeorge@gmail.com</a></p>
            </div>
          
        </div>
          
          </div>
        </div>
      </div>
    </section>



    <section  className="homeSection" style={{marginBottom:'20px'}}>
      <div className="container width">
        <div className="row">
          <div className="col-4 border bottom-parent">
            <h1 className="heading">Applications</h1>
            <p className="text-accent1 p-sm">
              These are some of the applications that I have made over the years. These include the very first applications that I made. If you are interested, do take a look.</p>
              <div className="row">
              <div className="col-12 text-center bottom">
                <button className="button"><Link to="/apps" className="no-decoration">Go To Apps</Link></button>
              </div>
            </div>
            
            
            
          </div>
          <div className="col-4 border bottom-parent">
            <h1 className="heading">Blog</h1>
           
           <p className="text-accent1 p-sm">
              "Don't think to write. Write to think." Here are some of the documentations of my experiences. Check it out!</p>
              <div className="row">
              <div className="col-12 text-center bottom">
                <button className="button"><Link to="/blog" className="no-decoration">Go To Blog</Link></button>
              </div>
            </div>
           
            
          </div>


          <div className="col-4 border  bottom-parent">
            <h1 className="heading">Videos</h1>
           
           <p className="text-accent1 p-sm">
             One of my creative outlet. This is one thing that I like to experiment with. 
             </p>
              <div className="row">
                <div className="col-12 text-center bottom">
                  <button className="button"><Link to="/gallery" className="no-decoration">Go To Videos</Link></button>
                </div>
              </div>
          </div>


        </div>
      </div>
    </section>
    {/* <section  className="homeSection">
      <div className="container width p-lg">
        <div className="row">
          <div className="col-12 heading">
            <h3 className="margin-sm">Want to work with me?</h3>
            <p>Drop me an email at <a href="mailto:contact.asishgeorge@gmail.com">contact.asishgeorge@gmail.com</a></p>
          </div>
          
        </div>
      </div>
    </section> */}
  </Layout>
  )
}

export default IndexPage
